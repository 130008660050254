import JsonData from "./JsonData";

const ModalState = {
    WelcomeModal: false,
    ContentModal: false,
    VideoModal: false,
    FlickrModal: false,
    PdfModal: false
}

const ModalStateObservers = {
    WelcomeModal: [],
    ContentModal: [],
    VideoModal: [],
    FlickrModal: [],
    PdfModal: []
}

const ModalContents = {
    MainModal: [],    
}

export const VideoData = {
    url: "",
    title: "",
}

export const PDFData = {
    url: "",
    title: "",
}

export const FlickrData = {
    url: ""
}

export function RegisterModalObserver(modal, observer){
    ModalStateObservers[modal].push(observer);
}

export function ChangeModalState(modal, state){
    ModalState[modal] = state;
    ModalStateObservers[modal].forEach(observer => observer(state));
}

export function GetModalContent(modal){
    return ModalContents[modal];
}

export function SetModalContent(modal, content){
    ModalContents[modal] = content;
}

window.ExternalChangeModalState = (modal, ref)=>{
    const path = ref.split('_');
    const content = JsonData[path[0]][path[1]];
    SetModalContent(modal, content);
    ChangeModalState(modal, true);
}