import { useState, useEffect, Fragment } from 'react';

import "./styles.css";

import { RegisterStateObserver, ChangeState } from "Interfaces/IntroInterface.js";
import { SetRegisterData } from "Interfaces/RegisterInterface";
import { CreateUser } from "Services/Firebase.js";

function Cadastro() {

  const [enabled, setEnabled] = useState(false);
  const [formName, setFormName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formPhone, setFormPhone] = useState("");
  const [formCompany, setFormCompany] = useState("");
  const [formRole, setFormRole] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function EnableElement() {
      setEnabled(true);
  }

  function DisableElement() {
      setEnabled(false);
  }

  function handlePhoneChange(input) {
    setFormPhone(phoneMask(input));
  }

  function handleRoleChange(value){
    setFormRole(value);
  }

  function OnSubmitRegisterForm(event){
    event.preventDefault();

    if(formPhone.length < 14){
      setErrorMessage("Telefone inválido");
      return;
    }

    setErrorMessage("");

    const RegisterData = {
      name: formName,
      email: formEmail,
      phone: formPhone,      
      company: formCompany,
      role: formRole,
      accept: true,
    }

    for(let key in RegisterData ){            
      SetRegisterData(key, RegisterData[key]);            
    } 

    CreateUser(RegisterData, RegisterCompleted, ShowErrorMessage);     
  }

  function RegisterCompleted(){
    ChangeState("RegisterToConfirm");
  }

  function ShowErrorMessage(message){
    setErrorMessage(message);
  }

  function phoneMask(phone){
    return phone.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, `$1-$2`)
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1')
  }

  useEffect(() => { 
    RegisterStateObserver("LoginToRegister", EnableElement);
    RegisterStateObserver("RegisterToLogin", DisableElement);   
    RegisterStateObserver("RegisterToConfirm", DisableElement);      
  },[]);

  return (
    <Fragment>
        { enabled ?
        <form onSubmit={OnSubmitRegisterForm}>
    <div className="cadastro_div">
        <div style={{cursor: 'pointer'}} onClick={()=>{ChangeState("RegisterToLogin");}} >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 12H5"
              stroke="#EDF4F4"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 19L5 12L12 5"
              stroke="#EDF4F4"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="title">
          Criar uma conta de acesso é rápido e gratuito!
        </div>
        <div className="input">
          <input type="text" name="name" placeholder="Nome completo" value={formName} onChange={(e) => setFormName(e.target.value)} minLength="5" required/>
        </div>
        <div className="input">
          <input type="text" name="company" placeholder="Empresa" value={formCompany} onChange={(e) => setFormCompany(e.target.value)} />
        </div>
        <div className="input">
          <input type="email" name="email" placeholder="E-mail" value={formEmail} onChange={(e) => setFormEmail(e.target.value)} required />
        </div>
        <div className="input">
          <input type="tel" name="phone" placeholder="Telefone" value={formPhone} onChange={(e) => handlePhoneChange(e.target.value)} required />
        </div>
        <div className="input">
          <select name="role" onChange={(e) => handleRoleChange(e.target.value)} defaultValue="" required>
            <option value="" disabled hidden>Cargo</option>
            <option value="diretoria">Diretoria</option>
            <option value="gerencia">Gerência</option>
            <option value="colaborador">Colaborador</option>
            <option value="estudante">Estudante</option>
            <option value="visitante">Visitante</option>
          </select>
        </div>
        <div className="checkbox">
          <input type="checkbox" required/>
          <label>
            Autorizo o tratamento dos meus dados pelo Metazonia, conforme termo de
            consentimento referente a LGPD
          </label>
        </div>
        <button type="submit" className="button">cadastrar</button>
        <p className="errorMessage">{errorMessage}</p>
    </div>
    </form>
    : null }
    </Fragment>
  );
}

export default Cadastro;
