import MusicButton from "../../Components/MusicButton";
import "./styles.css";

export default function FixedMenuButton(props) {
    return (
    <div className="divOpenBtn">
        <div className="backgroundBlur"></div>
        <div className="divBtn">
            <button className="openBtn" onClick={()=>{props.clickFunction();}}>
                <p>MENU</p>
                <p style={{marginTop: "-2.5px"}}>&#9776;</p>
            </button>            
        </div>&emsp;
        <MusicButton/>
    </div>
    );
}
