import { useState } from "react";
import "./styles.css";

import SlidebarMenuButton from "../../Components/SlidebarMenuButton";
import FixedMenuButton from "../FixedMenuButton";

import Logo from "../../Assets/metazonia.png";
import LogoCassina from "../../Assets/logo-cassina-menu.png";
import LogoManaus from "../../Assets/logo-manaus-menu.png";
import LogoSemtepi from "../../Assets/logo-semtepi-menu.png";
import Detail from "../../Assets/backgroungLoginDetail.png";
import MusicButton from "../../Components/MusicButton";

function Sidebar() {
  const [showSidebar, setshowSidebar] = useState(false);

  return (
    <>
    <div className="logo_fixa">
    <div className="backgroundBlur"></div>
      <img src={Logo} alt="" />
    </div>
      <div>
        <FixedMenuButton
          clickFunction={()=>{setshowSidebar(!showSidebar) }}
        />
      </div>
      <div className="sidebar">
        <div
          className="backdrop"
          style={showSidebar ? { display: "block" } : { display: "none" }}
          onClick={() => setshowSidebar(!showSidebar)}
        ></div>
        <div className={`sidenav ${showSidebar ? "show" : ""}`}>
          <div className="img_detail">
            <img src={Detail} alt="" />
            <img src={Detail} alt="" />
            <img src={Detail} alt="" />
            <img src={Detail} alt="" />
            <img src={Detail} alt="" />
            <img src={Detail} alt="" />
            <img src={Detail} alt="" />
          </div>
          <div className="sidebarHeader">
            <div>
              <img src={Logo} alt="" />
            </div>
            <button
              className="closebtn"
              onClick={() => setshowSidebar(!showSidebar)}
            >
              &times;
            </button>
          </div>
          
          <div className="sidebarContent">
            <div style={{marginBottom:"16px", width:"100%", height: "32px", display: "flex", justifyContent: "space-between"}}>
              <div className="volume_icon">
                <MusicButton/>              
              </div>
              <div style={{marginBottom:"16px", width:"150px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center", background: "#00A49D", marginRight: "32px", borderRadius: "8px", cursor: "pointer"}} onClick={()=>{window.NavigateTo("node1", ""); setshowSidebar(false); window.ExternalRegisterNavigation("vistaaerea");}}>
                <p style={{margin:"0", fontWeight:"bold", fontSize:"12px"}}>VISTA AÉREA</p>
              </div>
            </div>
            <SlidebarMenuButton closeFunction={() => setshowSidebar(false)} />
            <hr />
          </div>

          <div className="images_footer">
            <img src={LogoCassina} alt="" />
            <img src={LogoManaus} alt="" />
            <img src={LogoSemtepi} alt="" />
          </div>

          {/* <div className="sidebarFooter">
          <button>Precisa de ajuda?</button>
          <hr className="fullLine" />
        </div> */}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
