import "./styles.css";

function LinkExternoModal(props) {

  return (
    <div className="linkexternoModal">     
        <a href={props.link} target="_blank">
          <div className="content">
              {props.title}
          </div>
        </a>      
    </div>
  );
}

export default LinkExternoModal;
