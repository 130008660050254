import { useState, useEffect } from 'react';

import "./styles.css";

export default function SlidebarMenuButton(props){

    const ArrowIcon = () => (
        <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.04395 13L7.04395 7L1.04395 1" stroke="#F4455A" strokeWidth="2" strokeLinejoin="round"/>
        </svg>

    );


    return (
        <>
        <div className="sidebarParagraphs" onClick={()=>{window.NavigateTo("node64", ""); props.closeFunction();}}>
            <p>Home</p>            
        </div>
        <hr/>
        <div className="sidebarParagraphs" onClick={()=>{window.NavigateTo("node4", "-84"); props.closeFunction();}}>
            <p>Recepção</p>
        </div>
        <hr/>
        <div className="sidebarParagraphs" onClick={()=>{window.NavigateTo("node5", "0"); props.closeFunction();}}>
            <p>Casarão Cassina</p>
        </div>
        <hr/>
        <div className="sidebarParagraphs" onClick={()=>{window.NavigateTo("node10", "-90"); props.closeFunction();}}>
            <p>História</p>
        </div>
        <hr/>
        <div className="sidebarParagraphs" onClick={()=>{window.NavigateTo("node15", "178"); props.closeFunction();}}>
            <p>Metazônia</p>
        </div>
        <hr/>
        <div className="sidebarParagraphs" onClick={()=>{window.NavigateTo("node21", "-164"); props.closeFunction();}}>
            <p>Prefeitura de Manaus</p>
        </div>
        <hr/>
        <div className="sidebarParagraphs" onClick={()=>{window.NavigateTo("node44", "-98"); props.closeFunction();}}>
            <p>Semtepi</p>
        </div>
        <hr/>
        <div className="sidebarParagraphs" onClick={()=>{window.NavigateTo("node37", "-110"); props.closeFunction();}}>
            <p>Coworking</p>
        </div>
        {/* <hr/> */}
        {/* <div className="sidebarParagraphs">
            <p>Eventos</p>
        </div> */}
        </>
    );
}