import './App.css';
import Cadastro from './Templates/cadastro';
import Cadastrado from './Templates/cadastro/Cadastrado';
import Login from './Templates/Login';
import Sidebar from './Templates/Sidebar';
import Modal from "./Components/Modal";
import WelcomeModal from './Templates/ModalWelcome';
import ModalVideo from "Components/VideoModal/ModalVideo";
import ModalCentro from './Components/ModalCentro';
import BlockInput from "Templates/BlockInput";
import PDFmodal from "Components/PDFmodal/ModalPDF";
import FlickrModal from "Components/FlickrGallery";
import ButtonPanoramic from "Components/ButtonPanoramic"

function App() {
  return (
    <div className="App">
      <Cadastro />
      <Cadastrado />
      <Login />
      <BlockInput />
      <Sidebar/>
      <Modal/> 
      <WelcomeModal />
      <ModalVideo />
      <FlickrModal />
      <PDFmodal />
      <ButtonPanoramic />
    </div>
  );
}

export default App;
