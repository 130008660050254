import "./styles.css";

function TitleModal(props) {
  return (
    <div className="titleModal">
      <h2>{props.title}</h2>
    </div>
  );
}

export default TitleModal;
