const StateObservers = {
    LoginToRegister: [],
    RegisterToLogin: [],
    RegisterToConfirm: [],
    ConfirmToFinish: [],
    LoginToFinish: [],
}

export function RegisterStateObserver(stateChange, observer){
    StateObservers[stateChange].push(observer);
}

export function ChangeState(state){
    StateObservers[state].forEach(observer => observer());
}

const Music = {
    State: true,
}

const MusicObservers = {
    State: [],
}

export function RegisterMusicObserver(stateChange, observer){
    MusicObservers[stateChange].push(observer);
}

export function ChangeMusic(change, state){
    Music[change] = state;
    if(state){
        window.MusicVolumeOn();
    }else{
        window.MusicVolumeOff();
    }
    MusicObservers[change].forEach(observer => observer(state));
}
