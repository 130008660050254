import { useState, useEffect, Fragment } from 'react';

import "./styles.css";

import { RegisterStateObserver, ChangeState } from "Interfaces/IntroInterface.js";
import { GetRegisterData } from "Interfaces/RegisterInterface.js";

function Cadastrado() {

  const [enabled, setEnabled] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

    function EnableElement() {
        setName(GetRegisterData("name"));
        setEmail(GetRegisterData("email"));
        setEnabled(true);
    }

    function DisableElement() {
        setEnabled(false);
    }

  useEffect(() => { 
    RegisterStateObserver("RegisterToConfirm", EnableElement);
    RegisterStateObserver("ConfirmToFinish", DisableElement);        
  },[]);

  return (
    <Fragment>
        { enabled ?
        <div className="cadastrado">
        <div>
            {/* <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M19 12H5"
                stroke="#EDF4F4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 19L5 12L12 5"
                stroke="#EDF4F4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            </svg> */}
        </div>
        <div className="name">{name},</div>
        <div className="text">
            Parabéns, seu acesso foi criado e você já pode acessar a plataforma 360º
        </div>
        <div className="email">{email}</div>
        <div className="button" onClick={() => {ChangeState("ConfirmToFinish");}}>acessar</div>
        </div>
    : null }
    </Fragment>
  );
}

export default Cadastrado;
