import { useState, useEffect} from "react";

import "./styles.css";

import { VideoData, ChangeModalState } from "Interfaces/ModalInterface.js";

function OpenVideo(props) {

  const [thumbnail, setThumbnail] = useState("");
  const [video, setVideo] = useState("");

  function HandleVideoInfo(){
    if(props.type === "Youtube"){      
      setThumbnail(`https://img.youtube.com/vi/${props.videoCode}/0.jpg`);
      setVideo(`https://www.youtube.com/embed/${props.videoCode}`);
    }else if(props.type === "Vimeo"){       
        fetch(`https://vimeo.com/api/v2/video/${props.videoCode}.json`)
        .then(response => response.json())
        .then(data => {
          setThumbnail(data[0].thumbnail_large);
        });      
      setVideo(`https://player.vimeo.com/video/${props.videoCode}`);
    }
  }

  useEffect(()=>{
    HandleVideoInfo();
  },[]);

  return (
    <div className="open-video-modal" style={ props.gallery ? {width: "48%"} : {width: "100%"} }>
    <div className="openVideo" style={{ backgroundImage: 'url(' +thumbnail + ')', backgroundPosition: "center", backgroundSize: "cover"}} onClick={() => { VideoData.url = video; VideoData.title = props.title; ChangeModalState("VideoModal", true);}}>
      <div className="imageFilter">
      </div>
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 58C45.464 58 58 45.464 58 30C58 14.536 45.464 2 30 2C14.536 2 2 14.536 2 30C2 45.464 14.536 58 30 58Z" fill="#00A49D" stroke="#00A49D" strokeWidth="4" strokeLinejoin="round"/>
        <path d="M24.4004 18.8L41.2004 30L24.4004 41.2V18.8Z" stroke="white" strokeWidth="4" strokeLinejoin="round" />
      </svg>

    </div>
    { props.showTitle ? 
    <div className="openVideoTitle">
      <h3>{props.title}</h3>
    </div>
    : null }      
    </div>
  );
}

export default OpenVideo;
