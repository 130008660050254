import { useState, useEffect } from "react";

import "./styles.css";

import { RegisterMusicObserver, ChangeMusic } from "Interfaces/IntroInterface.js";

export default function MusicButton() {
  const [buttonState, setButtonState] = useState(true);

  const MusicOnIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 5L6 9H2V15H6L11 19V5Z"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M19.07 4.92999C20.9447 6.80527 21.9979 9.34835 21.9979 12C21.9979 14.6516 20.9447 17.1947 19.07 19.07M15.54 8.45999C16.4774 9.39763 17.0039 10.6692 17.0039 11.995C17.0039 13.3208 16.4774 14.5924 15.54 15.53"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );

  const MusicOffIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 5L6 9H2V15H6L11 19V5Z"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M23 9L17 15"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M17 9L23 15"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(()=>{
    RegisterMusicObserver("State", setButtonState)
  },[]);

  return (
    <div className="musicButton" onClick={() => ChangeMusic("State", !buttonState)}>
      {buttonState ? MusicOnIcon() : MusicOffIcon()}
    </div>
  );
}
