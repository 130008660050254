const UserData = {

}

const RegisterData = {
    presencial: false,
    code: ""
}

export function SetUserData(ref, state){
    UserData[ref] = state;    
}

export function GetUserData(data){
    return UserData[data];  
}

export function SetRegisterData(ref, state){
    RegisterData[ref] = state;    
}

export function GetRegisterData(data){
    return RegisterData[data];  
}

export function GetAllRegisterData(){
    return RegisterData;  
}
