import "./styles.css";

import JsonData from "Interfaces/JsonData.js";

function ImageModal(props) {
  return (
    <div className="imageModal">
      <img alt={props.name} src={JsonData.defaultFilePath + "img/" + props.img}/>
    </div>
  );
}

export default ImageModal;
