import React, { useState, useEffect } from "react";
import "./styles.css";

import DetailFooter from "../../Assets/detailsmenu.png";

function ModalCentro() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button
        onClick={() => {
          setShowModal(!showModal);
          window.initsound();
        }}
        className="openModal"
      >
        Open
      </button>
      <div className={`modalCentro ${showModal ? "modalCentroShow" : ""}`}>
        <div
          className="backdrop"
          onClick={() => {
            setShowModal(!showModal);
            window.initsound();
          }}
        ></div>
        <div className="modalCentroContainer">
          <div className="modalCentroHeader">
            <button
              onClick={() => {
                setShowModal(!showModal);
                window.initsound();
              }}
            >
             <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_60_419)">
                  <path
                    d="M13 1L1 13"
                    stroke="#00A49D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 1L13 13"
                    stroke="#00A49D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_60_419">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          <div className="modalCentroContent">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/xGqC2y0hnU8"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="modalCentroFooter">
            <img src={DetailFooter} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalCentro;
