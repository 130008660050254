import "./styles.css";

function ParagraphModal(props) {
  return (
    <div className="paragraphModal">
      { props.text ? 
      <p dangerouslySetInnerHTML={{ __html: props.text }}>
      </p>
      : null }
    </div>
  );
}

export default ParagraphModal;
