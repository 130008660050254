import { useState, Fragment } from "react";

export default function ButtonPanoramic(){

    const [show, setShow] = useState(false);

    window.ShowButtonPanoramic = (state) => {
        setShow(state);
    }

    return (
        <Fragment>
            { show ?
            <div style={{marginBottom:"16px", width:"160px", height: "40px", position: "fixed", bottom: "10px", left: "calc(50% - 80px)", display: "flex", justifyContent: "center", alignItems: "center", background: "#00A49D", marginRight: "32px", borderRadius: "8px", cursor: "pointer"}} onClick={()=>{window.ReturnLastNode();}}>
                <p style={{margin:"0", fontWeight:"bold", fontSize:"14px"}}>VOLTAR</p>
            </div>
            : null }
        </Fragment>
    );
}