import {useState, useEffect, Fragment, useRef} from "react";

import BGDesktop from "Assets/bg_desktop.jpg"

import { RegisterStateObserver } from "Interfaces/IntroInterface.js";
import { ChangeModalState } from "Interfaces/ModalInterface.js";

export default function BlockInput(){

    const [enabled, setEnabled] = useState(true);

    const BGRef = useRef();

    function EnableElement(){
        setEnabled(true);
    }

    function DisableElement(){
        window.LoginTransition();  
        BGRef.current.style.opacity = 0;
        setTimeout(() => {
            setEnabled(false);
            ChangeModalState("WelcomeModal", true);
        }, 3000);        

    }

    useEffect(() => { 
        RegisterStateObserver("ConfirmToFinish", DisableElement);
        RegisterStateObserver("LoginToFinish", DisableElement);        
      },[]);
    
    return (
        <Fragment>
        { enabled ?
            <div 
                style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9, backgroundColor: '#181826',  backgroundImage: "url(" + BGDesktop + ")",  backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', transition: 'opacity 1s', opacity: 1}}
                ref={BGRef}
            >
            </div>
            :<div></div>
        }
        </Fragment>
    );
}