import "./styles.css";

import { FlickrData, ChangeModalState } from "Interfaces/ModalInterface.js";

function FlickrButton(props) {

  return (
    <div className="flickrButton" onClick={()=>{FlickrData.url = props.url; ChangeModalState("FlickrModal", true);}}>    
          <div className="content">
              <p>{props.title}</p>
          </div>   
    </div>
  );
}

export default FlickrButton;
