import { useState, useEffect, useRef } from "react";
import "./styles.css";

import {
  RegisterModalObserver,
  ChangeModalState,
  GetModalContent,
} from "Interfaces/ModalInterface.js";

import FooterDetail from "../../Assets/detailsmenu.png";
import TitleModal from "../TitleModal";
import ParagraphModal from "../ParagraphModal";
import OpenVideo from "Components/VideoModal/OpenVideo";
import LinkExternoModal from "../LinkexternoModal";
import ImageModal from "../ImageModal";
import GaleriaModal from "../GaleriaModal";
import OpenPDF from "Components/PDFmodal/OpenPDF";
import FlickrButton from "Components/FlickrButton";
import ContactModal from "Components/ContactModal";

function Modal() {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [contentFlex, modalContentFlex] = useState("flex-start");

  const contentDiv = useRef();

  function HandleMainModalContent() {
    if (contentDiv.current.clientHeight < contentDiv.current.scrollHeight) {
      modalContentFlex("flex-start");
    } else {
      modalContentFlex("center");
    }
  }

  function SetNewModalContent() {
    let content = [];
    let currentContent = GetModalContent("ContentModal");
    currentContent.forEach((element, index) => {
        switch(element.type){
            case "title":
              content.push(<TitleModal key={index} title={element.title} />);
              break;
            case "text":
              content.push(<ParagraphModal key={index} text={element.text} />);
              break;
            case "video":
              let videoGallery = false;
              if(index > 0){
                if(currentContent[index - 1].type == "video"){
                  videoGallery = true;
                }
              }
              if(currentContent.length - 1 > index){
                if(currentContent[index + 1].type == "video"){
                  videoGallery = true;
                }
              }
              content.push(<OpenVideo key={index} type={element.typeLink} title={element.titleVideo} gallery={videoGallery} videoCode={element.link} />);
              break;
            case "link":
              content.push(<LinkExternoModal key={index} title={element.title} link={element.link} />);
              break;
            case "flickr":
              content.push(<FlickrButton key={index} title={element.title} url={element.link} />);
              break;
            case "pdf":
              content.push(<OpenPDF key={index} title={element.name} url={element.file} />);
              break;
            case "img":
              content.push(<ImageModal key={index} name={element.name} img={element.file} />);
              break;
            case "contact":
              content.push(<ContactModal key={index} endereco={element.endereco} time={element.time} phone={element.phone} />);
              break;
        }
    });

    setModalContent([content]);
    setShowModal(true);
  }

  function HandleModalState(state) {
    if (state) {
      SetNewModalContent();
    } else {
      setShowModal(false);
    }
  }

  useEffect(() => {
    RegisterModalObserver("ContentModal", HandleModalState);
  }, []);

  useEffect(() => {
    HandleMainModalContent();
  }, [showModal]);

  return (
    <>
      {/* <button onClick={() => setShowModal(!showModal)} className="openModal">Open</button> */}
      <div className={"backGroundModal"}>
        <div
          className={showModal ? "clickBackShow" : "clickBack"}
          style={{ display: showModal ? "flex" : "none" }}
          onClick={() => ChangeModalState("ContentModal", false)}
        ></div>

        <div className={`modal ${showModal ? "showModal" : ""}`}>
          <div className="modalHeader">
            <button
              className="closeModalBtn"
              onClick={() => ChangeModalState("ContentModal", false)}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_60_419)">
                  <path
                    d="M13 1L1 13"
                    stroke="#00A49D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 1L13 13"
                    stroke="#00A49D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_60_419">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >            
            <div style={{width: '100%', height: '100%', maxHeight: '100%', overflowY: 'hidden', display: 'flex', alignItems: 'center'}}>
              <div className="modalContent" ref={contentDiv} style={{alignItems: contentFlex}}>
                <div style={{width: '100%', height: '16px'}}></div>
                { modalContent.map((item, index) => {
                  return item
                }) }
                <div style={{width: '100%', height: '16px'}}></div>
              </div>
            </div>
            <div className="modalRodape" style={{backgroundImage: "url(" + FooterDetail + ")",  backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>  
            </div>
          </div>
          <div className="modalRodape">
            <div className="imgOpacity"></div>
            <img src={FooterDetail} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
